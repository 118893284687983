import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import HeadingBg from "@images/slider-img1.jpg"
import PageHeading from "@components/page-heading"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const SampleSubmissionGuidelines = () => {
  return (
    <Layout>
      <SEO title="Sample Submission Guidelines" description="" />
      <PageHeading heading="Sample Submission Guidelines" BgImg={HeadingBg} />
      <div className="page-section">
        <Container>
          <div className="table-mobile">
            <table className="tg">
              <thead>
                <tr>
                  <th className="tg-0lax bg-green text-white">Product</th>
                  <th className="tg-0lax text-center bg-green text-white">
                    Potency
                  </th>
                  <th className="tg-0lax text-center bg-green text-white">
                    Homogeneity
                  </th>
                  <th className="tg-0lax text-center bg-green text-white">
                    Residual
                  </th>
                  <th className="tg-0lax text-center bg-green text-white">
                    Microbials
                  </th>
                  <th className="tg-0lax text-center bg-green text-white">
                    Pesticides
                  </th>
                  <th className="tg-0lax text-center bg-green text-white">
                    Heavy Metals
                  </th>
                  <th className="tg-0lax text-center bg-green text-white">
                    Mycotoxins
                  </th>
                  <th className="tg-0lax text-center bg-green text-white">
                    Water Activity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tg-0lax1">Flower/Trim</td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Pre-Rolls</td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Kief</td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Solvent-based Concentrates</td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Water-based Concentrates</td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Heat/Pressure-based Concentrates</td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    {" "}
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Food-based Concentrates</td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Marjuana Products</td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Audited Products</td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax1">
                    Pressurized Metered Dose Inhalers
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Vaporizer Delivery Devices</td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax1">Industrial Hemp Products</td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax">
                    <StaticImage
                      src="../images/check.png"
                      width={22}
                      height={18}
                      layout="constrained"
                    />
                  </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default SampleSubmissionGuidelines
